const defaultOrderDetails = {
	customerId: null,
	preAmount: 0,
	pickupDate: null,
	pickupTime: null,
	dropOffDate: null,
	dropOffTime: null,
	deliveryNotes: '',
	Services: '',
	SubServices: '',
	products: '',
	couriers: '',
	orderAddress: {
		firstname: null,
		lastname: null,
		email: null,
		contactNo: null,
		postCode: '',
		addressLine1: '',
		addressLine2: '',
	},
}

const orderStore = {
	namespaced: true,
	state: {
		order: { ...defaultOrderDetails },
		hasOrder: false,
		nextRefToHome: false, // if set then redirect to home screen else checkout
	},
	getters: {
		HAS_ORDER(state) {
			return state.hasOrder
		},
		HAS_NEXT_REF_TO_HOME(state) {
			return state.nextRefToHome
		},
	},
	mutations: {
		SET_USER_ID(state, payload) {
			state.order.customerId = payload.id
		},
		SET_HAS_ORDER(state) {
			state.hasOrder = true
		},
		SET_NEXT_REF_TO_HOME(state) {
			state.nextRefToHome = true
		},
		RESET_HAS_ORDER(state) {
			state.hasOrder = false
		},
		RESET_ORDER(state) {
			state.order = { ...defaultOrderDetails }
		},
		RESET_NEXT_REF_TO_HOME(state) {
			state.nextRefToHome = false
		},
		SET_ORDER(state, payload) {
			state.order.customerId = payload.order.customerId
			state.order.preAmount = payload.order.preAmount
			state.order.pickupDate = payload.order.pickupDate
			state.order.pickupTime = payload.order.pickupTime
			state.order.dropOffDate = payload.order.dropOffDate
			state.order.dropOffTime = payload.order.dropOffTime
			state.order.deliveryNotes = payload.order.deliveryNotes
			state.order.Services = payload.order.Services
			state.order.SubServices = payload.order.SubServices
			state.order.products = payload.order.products
			state.order.couriers = payload.order.couriers
			state.order.orderAddress.firstname = payload.order.orderAddress.firstname
			state.order.orderAddress.lastname = payload.order.orderAddress.lastname
			state.order.orderAddress.email = payload.order.orderAddress.email
			state.order.orderAddress.contactNo = payload.order.orderAddress.contactNo
			state.order.orderAddress.postCode = payload.order.orderAddress.postCode
			state.order.orderAddress.addressLine1 = payload.order.orderAddress.addressLine1
			state.order.orderAddress.addressLine2 = payload.order.orderAddress.addressLine2
		},
	},
	actions: {
		SET_ORDER_DETAILS(context, payload) {
			context.commit('SET_ORDER', payload)
			context.commit('SET_HAS_ORDER')
		},
		RESET_ORDER_DETAILS(context) {
			context.commit('RESET_ORDER')
			context.commit('RESET_HAS_ORDER')
			context.commit('RESET_NEXT_REF_TO_HOME')
		},
		UPDATE_USER_ID(context, payload) {
			context.commit('SET_USER_ID', payload)
		},
	},
}

export default orderStore
