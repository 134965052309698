<template>
	<transition name="fade">
		<div v-show="isLoading" class="global__spinner--mask">
			<div class="global__spinner--wrapper">
				<div :style="styles" class="global__spinner">
					<div :style="cubeStyles" class="global__spinner--cube global__spinner--cube1"></div>
					<div :style="cubeStyles" class="global__spinner--cube global__spinner--cube2"></div>
					<div :style="cubeStyles" class="global__spinner--cube global__spinner--cube3"></div>
					<div :style="cubeStyles" class="global__spinner--cube global__spinner--cube4"></div>
					<div :style="cubeStyles" class="global__spinner--cube global__spinner--cube5"></div>
					<div :style="cubeStyles" class="global__spinner--cube global__spinner--cube6"></div>
					<div :style="cubeStyles" class="global__spinner--cube global__spinner--cube7"></div>
					<div :style="cubeStyles" class="global__spinner--cube global__spinner--cube8"></div>
					<div :style="cubeStyles" class="global__spinner--cube global__spinner--cube9"></div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'GlobalLoadingSpinner',
	props: {
		size: {
			default: '80px',
		},
		background: {
			default: '#fcae16',
		},
		duration: {
			default: '1.3s',
		},
	},
	computed: {
		isLoading() {
			const loading = this.$store.state.globalLoading
			this.handleShowHideScrollbar(loading)
			return loading
		},
		cubeStyles() {
			return {
				backgroundColor: this.background,
				animationDuration: this.duration,
			}
		},
		styles() {
			return {
				width: this.size,
				height: this.size,
			}
		},
	},
	methods: {
		handleShowHideScrollbar(isLoading) {
			if (isLoading) {
				document.body.style.overflowY = 'hidden'
			} else {
				document.body.style.overflowY = 'auto'
			}
		},
	},
}
</script>

<style scoped>
.global__spinner--mask {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	padding: 1.3rem;
	z-index: 12000;
	transition: opacity 0.3s ease;
}

.global__spinner--wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.global__spinner {
	display: inline-block;
}

.global__spinner * {
	line-height: 0;
	box-sizing: border-box;
}

.global__spinner--cube {
	width: 33%;
	height: 33%;
	float: left;
	animation: spinner-cubeGridScaleDelay 1.8s ease-in-out infinite;
}

.global__spinner--cube1 {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.global__spinner--cube2 {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

.global__spinner--cube3 {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.global__spinner--cube4 {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}

.global__spinner--cube5 {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.global__spinner--cube6 {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

.global__spinner--cube7 {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.global__spinner--cube8 {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}

.global__spinner--cube9 {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

@keyframes spinner-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		transform: scale3D(1, 1, 1);
	}
	35% {
		transform: scale3D(0, 0, 1);
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
