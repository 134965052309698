import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import orderStore from './orderStore'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: {
			firstName: null,
			lastName: null,
			address1: null,
			address2: null,
			email: null,
			id: null,
			mobileNo: null,
			postCode: null,
			plateform: 'Web',
			photoPath: null,
			city: null,
		},
		token: '',
		globalLoading: false,
		uspa: undefined,
	},
	plugins: [
		createPersistedState({
			paths: ['user', 'token', 'uspa'],
		}),
	],
	mutations: {
		SET_USER(state, user) {
			state.user.firstName = user.firstname
			state.user.lastName = user.lastname
			state.user.address1 = user.address1
			state.user.address2 = user.address2
			state.user.email = user.email
			state.user.id = user.id
			state.user.mobileNo = user.mobileNo
			state.user.postCode = user.postCode
			state.user.plateform = user.plateform
			state.user.photoPath = user.photoPath
			state.user.city = user.city
		},
		UPDATE_USER_DETAILS(state, payload) {
			state.user.firstName = payload.user.firstname
			state.user.lastName = payload.user.lastname
			state.user.address1 = payload.user.address1
			state.user.email = payload.user.email
			state.user.id = payload.user.id
			state.user.mobileNo = payload.user.mobileNo
			state.user.postCode = payload.user.postCode
			state.user.city = payload.user.city
		},
		UPDATE_USER_PROFILE_PHOTO(state, payload) {
			state.user.photoPath = payload.photoPath
		},
		CLEAR_USER(state) {
			state.user.firstName = null
			state.user.lastName = null
			state.user.address1 = null
			state.user.address2 = null
			state.user.email = null
			state.user.id = null
			state.user.mobileNo = null
			state.user.postCode = null
			state.user.plateform = 'Web'
			state.user.photoPath = null
			state.user.city = null
		},
		SET_TOKEN(state, token) {
			state.token = token
		},
		CLEAR_TOKEN(state) {
			state.token = ''
		},
		SET_GLOBAL_LOADING(state) {
			state.globalLoading = true
		},
		SET_PASS(state, uspa) {
			state.uspa = uspa
		},
		CLEAR_PASS(state) {
			state.uspa = undefined
		},
		RESET_GLOBAL_LOADING(state) {
			state.globalLoading = false
		},
	},
	getters: {
		GET_TOKEN(state) {
			return state.token
		},
		IS_AUTHENTICATED(state) {
			return state.token
		},
	},
	actions: {
		SIGN_UP(context, payload) {
			context.commit('SET_USER', payload.user)
			context.commit('SET_TOKEN', payload.accessToken)
			context.commit('SET_PASS', payload.uspa)
		},
		LOGIN(context, payload) {
			context.commit('SET_USER', payload.user)
			context.commit('SET_TOKEN', payload.accessToken)
			context.commit('SET_PASS', payload.uspa)
		},
		LOGOUT(context) {
			context.commit('CLEAR_USER')
			context.commit('CLEAR_TOKEN')
			context.commit('CLEAR_PASS')
			context.dispatch('orderStore/RESET_ORDER_DETAILS', null, { root: true }) // clear order details
			localStorage.removeItem('_scc') // clear state
		},
	},
	modules: {
		orderStore: orderStore,
	},
})
